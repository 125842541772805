@import 'libs/variables';
@import 'libs/normalize';
@import 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap';

html {
  font-family: Montserrat, sans-serif;
}

body {
  min-width: 100%;
  min-height: 100vh;
}

.stopHorizontalScroll {
  overflow-x: hidden !important;
}

/* scrollbar customize */
* {
  scrollbar-width: thin;
  scrollbar-color: #babac0 #fff;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  border: 3px solid #babac0;
  border-radius: 20px;
  background-color: #babac0;
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar:horizontal {
  height: 8px;
}

/* включает скролбар при открытии попапа */
body.ant-scrolling-effect {
  overflow: auto !important;
}

/* ant design components styles override */
.ant-form-item-explain,
.ant-form-item-extra {
  margin-bottom: 8px !important;
}

.ant-input-affix-wrapper-focused {
  border-bottom: 1px solid #40a9ff !important;
}

.ant-layout-sider-zero-width-trigger {
  top: -52px !important;
  right: auto !important;
  left: 6px !important;
}

.ant-tooltip-open > .ant-menu-title-content {
  display: none !important;
  visibility: hidden !important;
}

.ant-layout {
  background: #f6f6f6 !important;
}

.ant-upload-list-item-card-actions-btn {
  opacity: 1 !important;
}

.ant-tooltip-placement-right {
  display: none !important;
}

.edit-user__form-phone .react-tel-input .invalid-number-message {
  top: 36px;
  left: -2px;
  background: transparent;
  font-size: 14px;
}

.edit-user__form-phone .react-tel-input .form-control {
  width: 100%;
  height: 32px;
  border-radius: 2px;
}

.edit-user__form-phone .react-tel-input .form-control.invalid-number,
.edit-user__form-phone .react-tel-input .form-control.invalid-number:focus {
  border: 1px solid #ff4d4f;
  border-left-color: rgb(255 77 79);
  background-color: transparent;
}

.edit-user__form-phone .react-tel-input .flag-dropdown.invalid-number {
  border-color: #ff4d4f;
  border-right-color: #cacaca;
}

.document-card {
  width: 900px;
  border: 1px solid #ccc;
}
@media (max-width: 1340px) {
  .document-card {
    width: 100%;
  }
}

@media (max-width: 440px) {
  .document-card {
    margin: 0 -5px;
  }
}

.document-card .ant-card-body {
  padding: 15px;
}

.document__item .ant-card-bordered {
  margin-bottom: 10px;
  border: 1px solid #b9b9c1;
}

.document__upload-col > span {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.document__upload-col .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-text {
  margin-top: 0;
}

.document__upload-col .ant-upload-list.ant-upload-list-text {
  flex-wrap: nowrap;
  width: 145px;
}

@media (max-width: 767px) {
  .document__upload-col .ant-upload-list.ant-upload-list-text {
    position: static;
    width: 200px;
  }
}

.document-bnt__container.ant-col {
  width: 220px;
}

@media (max-width: 1000px) {
  .document-bnt__container.ant-col {
    width: 120px;
  }

  .document-bnt__container.ant-col .ant-row {
    flex-direction: column-reverse;
  }

  .document-bnt__container.ant-col .bnt-reject {
    margin-top: 10px;
  }
}
@media (max-width: 574px) {
  .document-bnt__container.ant-col {
    width: 100%;
    margin-top: 10px;
  }

  .document-bnt__container.ant-col .ant-row {
    flex-direction: row;
  }

  .document-bnt__container.ant-col .bnt-reject {
    margin-top: 0;
  }
}

.article-editor .ql-editor {
  min-height: 300px;
  background-color: #fbfbfb;
}

.article-editor .ql-toolbar {
  background-color: #c4d2ff;
}

.article-editor {
  .ql-formats {
    .ql-table {
      position: relative;

      .ql-custom-select {
        position: absolute;
        display: none;
        min-width: 100%;
        padding: 4px 8px;
        white-space: nowrap;
        background-color: #fff;
      }

      &.ql-expanded {
        .ql-custom-select {
          z-index: 1;
          top: 100%;
          display: block;
          margin-top: -1px;
        }
      }
    }
  }
}

.preview-upload span.ant-upload.ant-upload-btn {
  padding: 0;
}

.preview-upload.ant-upload.ant-upload-drag {
  height: auto;
  border: 3px dashed #1890ff;
}

.ant-upload.ant-upload-drag:hover div[class*='EditArticle_UploadWrapper'],
.ant-upload.ant-upload-drag:active div[class*='EditArticle_UploadWrapper'],
.ant-upload.ant-upload-drag:focus div[class*='EditArticle_UploadWrapper'] {
  opacity: 1;
}

button:not(:disabled):focus-visible {
  transition: outline-offset 0s, outline 0s;
  outline: 2px solid #91caff;
  outline-offset: 1px;
}

.ant-form-item-required {
  &::after {
    display: block !important;
    margin-left: 4px !important;
    content: '*' !important;
    color: #ff4d4f !important;
    font-size: 14px !important;
    line-height: 1 !important;
  }
}
