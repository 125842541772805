.container {
  width: 100%;
  margin-top: 20px;
  padding: 20px 0;
}

.container > div {
  margin-bottom: 20px;
}

.rowTitle {
  margin-right: 5px;
  font-weight: 600;
}

.card {
  margin-bottom: 20px;
}

.cardBody {
  cursor: pointer;
}

.cardTitle {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.loading {
  height: 200px;
}
