.header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.username {
  margin-right: 20px;
  margin-bottom: 0;
  text-overflow: ellipsis;
  color: #fff;
}
