.avatarUploader {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 50%;
}

.avatarWrapper {
  position: absolute;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgb(0 0 0 / 50%);
}

.avatarUploader:hover .avatarWrapper,
.avatarWrapperActive {
  opacity: 1;
}
