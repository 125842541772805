.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerLogo {
  max-width: 160px;
  content: url('../../../assets/images/svg/Amigoweb_logo.svg');
}

@media (max-width: 480px) {
  .headerLogo {
    max-width: 28px;
    content: url('../../../assets/images/svg/Amigoweb_logo_min.svg');
  }

  .header {
    padding-right: 30px !important;
  }
}
