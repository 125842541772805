.container {
  display: flex;
  overflow: visible;
  flex-direction: column;
  width: 100%;
}

.search {
  display: flex;
  overflow: visible;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search > div {
  width: auto;
  margin-top: 10px;
}

.reset {
  width: 46px !important;
}

.filterSelect {
  width: 160px !important;
  margin-left: 10px !important;
}

.topMenu {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.topMenu > h3,
.topMenu > a {
  margin: 5px 0;
}

.sortContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 180px !important;
  margin-right: 0 !important;
  margin-left: 15px !important;
}

@media (max-width: 480px) {
  .topMenu,
  .search {
    padding: 0 25px !important;
  }
}
