.ErrorFallbackCritical__container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ErrorFallbackCritical__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ErrorFallbackCritical__content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.ErrorFallbackCritical__button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;

  &:focus {
    outline: none;
  }
}
