.menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
}

.menu > div {
  margin: 10px 0;
}

.menu > div > span {
  width: 200px !important;
}
