.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.containerMin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mainPart {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.header {
  width: 100%;
  height: 64px;
  padding-right: 50px;
}

.header > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.logo > span {
  width: 250px !important;
}

.menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
}

.menu > div {
  margin: 10px 0;
}

.menu > div > span {
  width: 200px !important;
}

.card {
  width: 100%;
  padding-top: 20px;
}

@media (max-width: 768px) {
  .sider {
    display: none !important;
  }
}

.LoaderScreen__loader {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  background: white;

  &.no_absolute {
    position: relative;
    z-index: initial;
  }
}
