.listTableContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
}

.listTable {
  width: 100%;
}

.columnTitle {
  font-weight: bold;
}

.row {
  cursor: pointer;
}

.row:hover > td {
  background-color: rgb(241 240 240) !important;
}
