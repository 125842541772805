.mainPart {
  display: flex;
  flex-direction: row;
}

.content {
  flex: 1 auto;
  padding: 25px;
}

.pageLoader {
  height: 100vh;
}

:global(.ant-layout.ant-layout-has-sider) {
  height: 100%;
  min-height: calc(100vh - 64px);
}

@media (max-width: 480px) {
  .content {
    padding: 25px 5px;
  }
}

.AppLayout__wrapper_default {
  width: 100%;
}
