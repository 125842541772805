@import 'variables';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  outline: none;
}

html {
  text-size-adjust: 100%;
}

body {
  color: $black;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a,
p,
b,
h1,
h2,
h3,
h4,
h5 {
  color: $black;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
}

a {
  text-decoration: none;
}

@mixin noselect {
  /* Safari */

  /* Konqueror HTML */

  /* Old versions of Firefox */

  /* Internet Explorer/Edge */
  user-select: none;
  -webkit-touch-callout: none; /* iOS Safari */

  /* Non-prefixed version, currently
                                   supported by Chrome, Opera and Firefox */
}
